//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-396:_1264,_5316,_5084,_8524,_5284,_1872,_404,_1704;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-396')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-396', "_1264,_5316,_5084,_8524,_5284,_1872,_404,_1704");
        }
      }catch (ex) {
        console.error(ex);
      }